import React from "react";

const Contact = () => {
    return (
        <div className="page">
        <h1>Contact</h1>
        <p>Here's how to contact me...</p>
        </div>
    );
}

export default Contact;